head
    meta(charset='utf-8')
    meta(name='viewport', content='width=device-width, initial-scale=1.0')
    title Wallester
    link(rel='stylesheet', href='/index.css', type="text/css")
    meta(property='og:title', content='Make Card Issuing Simple')
    meta(property='og:url', content='https://wallester.eu/')
    meta(property='og:description', content='Wallester is a FinTech company licensed by Estonian FSA and member of Visa, who implemented new All-in-One card issuing as a service ecosystem, which allows companies to issue white-label Visa payment cards using "pay as you grow" pricing model within short timeline.')
    meta(property='og:image', content='http://wallester.eu/homepage/favicons/wallester-ogp.png')
    meta(property='og:image:width', content='1200')
    meta(property='og:image:height', content='630')
    meta(property='og:type', content='website')

    link(rel='shortcut icon', href='materials/favicons/favicon.ico')
    link(rel='apple-touch-icon', href='materials/favicons/apple-touch-icon.png')
    link(rel='apple-touch-icon', sizes='57x57', href='materials/favicons/apple-touch-icon-57x57.png')
    link(rel='apple-touch-icon', sizes='72x72', href='materials/favicons/apple-touch-icon-72x72.png')
    link(rel='apple-touch-icon', sizes='76x76', href='materials/favicons/apple-touch-icon-76x76.png')
    link(rel='apple-touch-icon', sizes='114x114', href='materials/favicons/apple-touch-icon-114x114.png')
    link(rel='apple-touch-icon', sizes='120x120', href='materials/favicons/apple-touch-icon-120x120.png')
    link(rel='apple-touch-icon', sizes='144x144', href='materials/favicons/apple-touch-icon-144x144.png')
    link(rel='apple-touch-icon', sizes='152x152', href='materials/favicons/apple-touch-icon-152x152.png')
    link(rel='apple-touch-icon', sizes='180x180', href='materials/favicons/apple-touch-icon-180x180.png')
.card-service.fix-width.background-rounded
    h3.mirror Card issuing
    h3.title Card Issuing
        span  as&nbsp;a&nbsp;Service

    .cards-wrapper
        .row-1
            .card.background-rounded.one
                .icon
                    include ../../../assets/materials/svg/services/clock.svg
                .right-side
                    h3.card-title Rapid launch and scaling
                    p.description.line-clamp.line-clamp-3
                        | Wallester's flexible turn-key card issuing solution
                        |
                        strong guarantees the fastest time to market by minimizing integration complexity.
                        |
                        | We will handle all integration needs with different service providers and always find the best solution to fit your needs.
                        |
                        strong Scaling possibilities are virtually limitless on AWS
                        |
                        | cloud computing infrastructure with no delays due to hardware or connectivity limitations. Guaranteed access to new services through 
                        |
                        strong seamless system upgrades.
                    a.button.button-modal.waves-effect.waves-light.modal-trigger(data-target='platform') Read More
            .card.background-rounded.two
                .icon
                    include ../../../assets/materials/svg/services/safe.svg
                .right-side
                    h3.card-title Best-of-class pricing model
                    p.description.line-clamp.line-clamp-3
                        | Lower your issuing costs by eliminating high startup, system maintenance and development fees. All Wallester clients will have
                        |
                        strong access to the latest platform functionalities and technologies
                        |
                        | immediately after introduction and without any additional costs. Wallester will always strive to maintain the industry’s cost leadership and
                        |
                        strong enable the scale efficiencies for all its clients.
                    a.button.button-modal.waves-effect.waves-light.modal-trigger(data-target='modalCost') Read More
        .row-2
            .card.background-rounded.three
                .icon
                    include ../../../assets/materials/svg/services/network.svg
                .right-side
                    h3.card-title Technology platform
                    p.description.line-clamp.line-clamp-3
                        strong Technology is the heart of our platform
                        |
                        | - our inhouse engineering team takes great pride in developing the best-of-class solutions for any of your payment card issuing needs.
                        |
                        strong Wallester's offering bundles 30+ different services
                        | , including card management, card balance, processing and many others and
                        |
                        strong integrates with leading 3D Secure, Fraud Monitoring, Card Personalisation providers
                        |
                        | to make sure that our clients have access to all the desired functionality and features.
                    a.button.button-modal.waves-effect.waves-light.modal-trigger(data-target="modalFeatures") Read More
            .card.background-rounded.four
                .icon
                    include ../../../assets/materials/svg/services/web-design.svg
                .right-side
                    h3.card-title White-labeling done right
                    p.description.line-clamp.line-clamp-3
                        strong We believe that you should always be in control
                        |
                        | and our white label ecosystem is built with that in mind. Clients
                        |
                        strong can fully integrate with Wallester REST API
                        |
                        | and operate in their own web, mobile or any other type of environment. In addition, Wallester also provides
                        |
                        strong white-label Back Office Portal
                        | , which can be used to support and manage your card issuing business. Throughout the card lifecycle (including 3D Secure screens)
                        |
                        strong you control all the aspects of the service branding and looks.
                    a.button.button-modal.waves-effect.waves-light.modal-trigger(data-target="modalTailor") Read More

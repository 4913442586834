require('materialize-css/dist/js/materialize.min');
var VimeoPlayer = require('@vimeo/player/dist/player');


//----------------------------------------------
// Start cards modal controller

document.addEventListener('DOMContentLoaded', function() {
    M.Modal.init(document.querySelectorAll('.modal-cards'));
    document.querySelectorAll('.modal .arrow-back').forEach( function (item) {
        item.addEventListener('click', function () {
            var modal = M.Modal.getInstance(item.closest('.modal'));
            modal.close();
        })
    } )
});


// End card modal controller
//------------------------------------------------

//----------------------------------------------
// Start vimeo modal controller

var vimeoModalInstance, vimeoPlayerInstance;

document.addEventListener('DOMContentLoaded', function () {
    var vimeoModal = document.getElementById('vimeo-modal');
    var vimeoModalFrame = document.getElementById('video-iframe');
    vimeoPlayerInstance = new VimeoPlayer(vimeoModalFrame);

    M.Modal.init(vimeoModal, {
        onCloseStart: function () {
            vimeoPlayerInstance.pause();
        },
        opacity: 0.7
    });

    vimeoModalInstance = M.Modal.getInstance(vimeoModal);
});

document.getElementById('video-trigger').addEventListener('click', function () {
    vimeoModalInstance.open();
    vimeoPlayerInstance.play();
});

// End vimeo modal controller
//------------------------------------------------




//----------------------------------------------
// Start tab controller


var toggleTabs = function (isRegurarWay) {
    //Defining elements
    var regularWayTab = document.querySelector('.diagram-section .tabs-wrapper .tab-1');
    var wallesterWayTab = document.querySelector('.diagram-section .tabs-wrapper .tab-2');
    var caret = document.querySelector('.diagram-section .tabs-wrapper .caret');
    var diagramWrapper = document.querySelector('.diagram-section .diagram-wrapper');


    //Total controller
    if (isRegurarWay) {
        regularWayTab.classList.add('active');
        wallesterWayTab.classList.remove('active');
        caret.classList.remove('toggled');
        diagramWrapper.classList.add('company-view');
    } else {
        regularWayTab.classList.remove('active');
        wallesterWayTab.classList.add('active');
        caret.classList.add('toggled');
        diagramWrapper.classList.remove('company-view');
    }
};

// Control tab 1
document.querySelector('.diagram-section .tabs-wrapper .tab-1').addEventListener('click', function () {
   toggleTabs(true);
});

// Control tab 2
document.querySelector('.diagram-section .tabs-wrapper .tab-2').addEventListener('click', function () {
    toggleTabs(false);
});

// End tab controller
//------------------------------------------------


//Announcement banner
document.addEventListener('DOMContentLoaded', function() {
    var bannerList = document.querySelector('.news-banner').classList;
    setTimeout(function () {
        bannerList.add('opened');
    }, 500);
    setTimeout(function () {
        bannerList.remove('opened');
    }, 10500);
});

document.querySelector('.news-banner .close').addEventListener('click', function () {
    document.querySelector('.news-banner').classList.remove('opened');
});

document.querySelector('.news-banner a').addEventListener('click', function () {
    var news = document.querySelector('.news.fix-width');
    news.scrollIntoView({behavior: "smooth"});
    document.querySelector('.news-banner').classList.remove('opened');
});
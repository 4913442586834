.footer
    .footer-wrapper
        a.logo-footer(href="/") Wallester AS
        .company-info
            a.report.links(target="_blank" href="/materials/docs/Wallester AS Annual Report 2017.eng.pdf")
                .icon
                    include ../../assets/materials/svg/footer/document.svg
                | Annual report 2017
            .legal-info
                .icon
                    include ../../assets/materials/svg/footer/stamper.svg
                p Reg.number&#8194;11812882
            a.adress.links(target="_blank"  href="https://www.google.com/maps/place/Parda+10,+Kesklinn,+Kesklinna,+10151+Harju+maakond")
                .icon
                    include ../../assets/materials/svg/footer/location.svg
                | Parda 10/12, 10151, Tallinn, Estonia
            a.email.links(href="mailto:info@wallester.eu")
                .icon
                    include ../../assets/materials/svg/footer/mail.svg
                | info@wallester.eu
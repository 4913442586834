.ecosystem.fix-width.background-rounded
    h3.mirror Ecosystem
    h3.title Wallester&#8194;
        span Ecosystem
    p.description Wallester's all-in-one payment card ecosystem as an alternative to in-house issuing infrastructure.

    include diagram

    table.highlight.table
        thead.table-header
            tr
                th.description.description-title
                th.regular.regular-title In-house solution
                th.wallester.wallester-title Wallester solution
        tbody.table-body
            tr
                td.description Time to market
                td.regular 12+ month
                td.wallester <3 months
            tr
                td.description Set-up costs
                td.regular 1.0+ million EUR
                td.wallester <0,1 million EUR
            tr
                td.description Integrations
                td.regular 3+
                td.wallester Wallester only
            tr
                td.description Solution complexity
                td.regular Complex legal and process management
                td.wallester Plug-and-play
            tr
                td.description Maintenance costs
                td.regular 50%+ of budget
                td.wallester 0% (Wallester provided)
            tr
                td.description Additional product launch
                td.regular 3+ months
                td.wallester 1-2 weeks
            tr
                td.description New features
                td.regular Developed separately
                td.wallester Included in offering

    .table-mobile.highlight
        .header
            h3.table-title In-house solution
            h3.table-title Wallester solution
        .body
            .row-1.time
                h4.row-title Time to market
                p.regular 12+ month
                p.wallester <3 months
            .row-2.costs
                h4.row-title Set-up costs
                p.regular 1.0+ million EUR
                p.wallester <0,1 million EUR
            .row-3.integratons
                h4.row-title Integrations
                p.regular 3+
                p.wallester Wallester only
            .row-4.complexity
                h4.row-title Solution complexity
                p.regular Complex legal and process management
                p.wallester Plug-and-play
            .row-5.maintenance
                h4.row-title Maintenance costs
                p.regular 50%+ of budget
                p.wallester 0% (Wallester provided)
            .row-6.additional
                h4.row-title Additional product launch
                p.regular 3+ months
                p.wallester 1-2 weeks
            .row-7.complexity
                h4.row-title New features
                p.regular Developed separately
                p.wallester Included in offering

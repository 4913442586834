.hero
    .logo
        include ../../../assets/materials/svg/logo-header.svg
    .hero-wrapper
        .left-side
            h1.title Making Card
                span.liner 
                | Issuing&#8194;
                span Simple
            p.description Wallester is a licensed payment service provider specializing in offering all-in-one white label card issuing, processing and payment transaction solutions. Our innovative SaaS ecosystem offers the best in class speed to market, flexible pay as you grow pricing model and requisite compliance maintenance and infrastructure support.
            .actions-wrapper
                a.button.button-gradient.waves-effect.waves-light(href="https://goo.gl/forms/rZquQDubaVuzSkX33" target="_blank") Get Started
                    span.inner-block
                a.button.button-experts.waves-effect.waves-light(href="mailto:sales@wallester.eu") Contact Experts
        .right-side
            img.laptop#video-trigger(src="/materials/img/homepage/inner/hero-right.png")

    .solutions
        .solutions-wrapper
            h3.subtitle Best in class solutions for
            .solution.consumer
                include ../../../assets/materials/svg/solutions/collecting.svg
                p.depiction Consumer<br>credit providers
            .solution.bank
                include ../../../assets/materials/svg/solutions/header-bank-icon.svg
                p.depiction Banks
            .solution.fintech
                include ../../../assets/materials/svg/solutions/fintech-icon.svg
                p.depiction FinTech
    .logos-wrapper
        img.visa(src="/materials/img/homepage/logos/visa.png")
        img.inspection(src="/materials/img/homepage/logos/finans-inspection.png")
        img.worldline(src="/materials/img/homepage/logos/worldline.png")
        img.evry(src="/materials/img/homepage/logos/evry.png")
        img.lighthouse(src="/materials/img/homepage/logos/lighthouse.png")
        img.cio(src="/materials/img/homepage/logos/cio.png")

    #vimeo-modal.modal
        .modal-content
            #video-iframe-wrapper
                iframe#video-iframe(src='https://player.vimeo.com/video/273674752?title=0&byline=0&portrait=0', style='width: 100%; height: 100%', allowfullscreen='')

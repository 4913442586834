.news.fix-width
    h3.mirror Our News
    h3.title Our&#8194;
        span News

    .news-big.background-rounded
        .left-side
            img(src="/materials/img/homepage/news/news-big.png")
        .right-side
            h3.title Lighthouse 2019
                span Winners & Finalists
            p.description We are happy to announce that after months of team effort, Wallester is the winner of 2019 Lighthouse class in Estonia & Baltics and one of the 3 finalists out of 15 in the Lighthouse Development Program
            p.description In the final round of the Lighthouse Program at Money 20/20 we will be competing for the overall program champion title against regional winners from Denmark and Norway. We are very grateful for the opportunity and looking forward to the final in Amsterdam.
            a.button.button-gradient.waves-effect.waves-light(href="https://www.mclighthouse.com/news/2019/5/21/meet-the-finalists-of-lighthouse-batch-2" target="_blank") Lighthouse meet the finalists
                span.inner-block

    .news-wrapper
        a.news-card.background-rounded.clickable(href="https://www.baltictimes.com/wallester_starts_live_testing_of_their_new_contactless_payment_method_in_the_baltics_and_the_nordics/" target="_blank")
            img(src="/materials/img/homepage/news/news-1.png")
            p.description.clickable Wallester starts live testing of their new contactless payment method in the Baltics and the Nordics.
        a.news-card.background-rounded.clickable(href="https://www.mclighthouse.com/news/ldp-2019" target="_blank")
            img(src="/materials/img/homepage/news/news-2.png")
            p.description.clickable Wallester has been chosen as one of the participants in this year's Mastercard Lighthouse program.
        a.news-card.background-rounded.clickable(href="https://www.linkedin.com/pulse/new-generation-visa-payment-cards-jekaterina-erikson/" target="_blank")
            img(src="/materials/img/homepage/news/news-3.png")
            p.description.clickable Wallester brings new generation Visa cards to Koduliising’s customers in Estonia.


.diagram-section
    .tabs-wrapper
        .tab-1.active
            h3.tab-title Traditional way
        .tab-2.unactive
            h3.tab-title Wallester way
        .caret
    .diagram-wrapper.company-view
        .idea.card-holder
            .icon
                include ../../../assets/materials/svg/diagram/idea.svg
            h4.diagram-title Business idea
        .caret-wrapper.caret-1
            .dot.dot-top
            .vertical-line
            .dot.dot-bottom
            .dot.dot-moving
        .company.card-holder
            .icon
                include ../../../assets/materials/svg/diagram/company.svg
            h4.diagram-title Your company
        .caret-wrapper.caret-2
            .dot.dot-top
            .vertical-line
            .dot.dot-bottom
            .dot.dot-moving
        .wallester
            .left-side
                include ../../../assets/materials/svg/diagram/left-block.svg
            .wallester-wrapper.card-holder
                .icon
                    include ../../../assets/materials/svg/diagram/wallester.svg
            .right-side
                include ../../../assets/materials/svg/diagram/right-block.svg
        .caret-wrapper.caret-3
            .dot.dot-top
            .vertical-line
            .dot.dot-bottom
            .dot.dot-moving
        .market.card-holder
            .icon
                include ../../../assets/materials/svg/diagram/market.svg
            h4.diagram-title Market

#platform.modal.modal-cards
    .modal-content
        .header-wrapper
            include ../../../assets/materials/svg/arrow-back.svg
            h4.modal-title Rapid launch and scaling
        .content-wrapper
            p.modal-text
                | Wallester's flexible turn-key card issuing solution
                |
                strong guarantees the fastest time to market by minimizing integration complexity.
                |
                | We will handle all integration needs with different service providers and always find the best solution to fit your needs.
                |
                strong Scaling possibilities are virtually limitless on AWS
                |
                | cloud computing infrastructure with no delays due to hardware or connectivity limitations. Guaranteed access to new services through 
                |
                strong seamless system upgrades.



#modalCost.modal.modal-cards
    .modal-content
        .header-wrapper
            include ../../../assets/materials/svg/arrow-back.svg
            h4.modal-title Best-of-class pricing model
        .content-wrapper
            p.modal-text
                | Lower your issuing costs by eliminating high startup, system maintenance and development fees. All Wallester clients will have
                |
                strong access to the latest platform functionalities and technologies
                |
                | immediately after introduction and without any additional costs. Wallester will always strive to maintain the industry’s cost leadership and
                |
                strong enable the scale efficiencies for all its clients.



#modalFeatures.modal.modal-cards
    .modal-content
        .header-wrapper
            include ../../../assets/materials/svg/arrow-back.svg
            h4.modal-title Technology platform
        .content-wrapper
            p.modal-text
                strong Technology is the heart of our platform
                |
                |  - our inhouse engineering team takes great pride in developing the best-of-class solutions for any of your payment card issuing needs.
                |
                strong Wallester's offering bundles 30+ different services
                | , including card management, card balance, processing and many others and
                |
                strong integrates with leading 3D Secure, Fraud Monitoring, Card Personalisation providers
                |
                | to make sure that our clients have access to all the desired functionality and features.


#modalTailor.modal.modal-cards
    .modal-content
        .header-wrapper
            include ../../../assets/materials/svg/arrow-back.svg
            h4.modal-title White-labeling done right
        .content-wrapper
            p.modal-text
                strong We believe that you should always be in control
                |
                | and our white label ecosystem is built with that in mind. Clients
                |
                strong can fully integrate with Wallester REST API
                |
                | and operate in their own web, mobile or any other type of environment. In addition, Wallester also provides
                |
                strong white-label Back Office Portal
                | , which can be used to support and manage your card issuing business. Throughout the card lifecycle (including 3D Secure screens)
                |
                strong you control all the aspects of the service branding and looks.